import React, { useState } from 'react';
import { useCalendarTheme } from '../../theme';
import PropTypes from 'prop-types';
import TemplateRender from '../../util/TemplateRender';
import { UnlockFilled, LockFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { supportsTouch, getUnlock } from '../../util/lockdrag.js';

function DailyCalendarHeader({ columns, columnRef }) {
  const { t } = useTranslation();

  const { computedStyles, theme: CalendarTheme, template } = useCalendarTheme();
  const [unlockDrag, setUnlockDrag] = useState(getUnlock());

  const toggleIconLock = () => {
    const value = unlockDrag ? 0 : 1;
    localStorage.setItem('unlockDrag', value);
    setUnlockDrag(value);
    // dnd need refresh page
    window.location.href = '';
  };

  return (
    <div
      className="calendar-header flex-row"
      style={{ height: CalendarTheme.header.height }}
      data-testid="calendar-header"
    >
      <div
        className="calendar-hours-header"
        style={computedStyles.calendarHourHeaderStyle}
      >
        {supportsTouch && (
          <div
            className="w-100 h-100 text-white text-center cursor-pointer pt-2"
            onClick={toggleIconLock}
            title={t('calendar.icon_lock_drag')}
          >
            {!unlockDrag ? (
              <LockFilled style={{ fontSize: '18px', color: '#ccc' }} />
            ) : (
              <UnlockFilled style={{ fontSize: '18px', color: '#fff' }} />
            )}
          </div>
        )}
      </div>
      <div
        className="calendar-columns flex-row overflow-x-scroll"
        id="calendarscrollLeft"
        ref={columnRef}
        style={computedStyles.calendarHeaderColumnContainerStyle}
      >
        {!!columns.length &&
          columns.map((column) => (
            <div
              className="calendar-column calendar-column-header"
              key={column.id}
              style={computedStyles.calendarColumnHeaderStyle}
            >
              <TemplateRender
                template={template.headerColumnTemplate || null}
                item={column}
                type={'daily'}
              >
                <div className="flex-column-center h-100">
                  <p className="m-0 text-center">{column.title}</p>
                </div>
              </TemplateRender>
            </div>
          ))}
      </div>
    </div>
  );
}

DailyCalendarHeader.propTypes = {
  template: PropTypes.object,
  columnRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  columns: PropTypes.array.isRequired,
};

export default DailyCalendarHeader;
