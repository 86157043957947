import React, { useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import './FeLogin.less';
import { weblogo } from '../../components';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import {
  Fsetapp,
  // fetchShopDetail,
  frontendLogin,
  frontendLogout,
  isFrontendLoggedIn,
  loginCustomer,
  shopDetail,
} from '../../features/frontend/frontendSlice';
import { setFrontendTokenInStorage } from '../../services/token';
import { logHandler } from '../../handlers/LogHandler';
import { t } from 'i18next';
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from '../../services/language';

const FeLogin = () => {
  const isLoggedIn = useSelector(isFrontendLoggedIn);
  const sDetail = useSelector(shopDetail);

  const dispatch = useDispatch();
  const history = useHistory();

  const current = getCurrentLanguage();

  const options = [
    {
      value: 'it',
      label: t('common:italian'),
    },
    {
      value: 'en',
      label: t('common:english'),
    },
    {
      value: 'fr',
      label: t('common:french'),
    },
  ];

  localStorage.removeItem('fe-isEmbed');

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    window.location.reload();
  };

  const loginUser = (data) => {
    dispatch(loginCustomer(data))
      .unwrap()
      .then((res) => {
        if (res.status !== 200 && res.message) {
          notification['error']({
            message: res.message,
          });
        }
        dispatch(res.status === 200 ? frontendLogin() : frontendLogout());
        res.status === 200 && setFrontendTokenInStorage(res.participant.token);
      })
      .catch((err) => {
        logHandler.error(err);
      });
  };

  useEffect(() => {
    isLoggedIn && history.push('/hairdresser');
  }, [isLoggedIn, history]);

  // useEffect(() => {
  //   dispatch(fetchShopDetail());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(Fsetapp());
  }, [dispatch]);

  const launchAppOnAndroidDevice = () => {
    if (/iPhone|iPod|iPad/.test(navigator.platform)) {
      if (sDetail.ios_url) return sDetail.ios_url;
      return null;
    }
    if (sDetail.android_url) return sDetail.android_url;
    return sDetail.ios_url;
  };

  const mobileAndTabletCheck = () => {
    let check = false;
    (function (a) {
      // eslint-disable-next-line no-use-before-define, no-useless-escape
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4),
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const isMobileOrTablet = mobileAndTabletCheck();
  const url = launchAppOnAndroidDevice();

  return (
    <div className="login-container" style={{ backgroundColor: '#4A4E69' }}>
      <div className="logo-container">
        <img src={weblogo} alt="Logo" className="logo" />
      </div>
      <div className="login-form">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
          }}
        >
          <h2>Login</h2>
          <Select
            defaultValue={current}
            onChange={changeLanguage}
            style={{
              backgroundColor: 'transparent',
              border: 'solid #D4D4D4 1px',
              color: '#7D7D7D',
              maxWidth: '100px',
              maxHeight: '30px',
            }}
            className="language-select select-selector"
            bordered={false}
            options={options.map((option) => ({
              ...option,
              label: <span>{option.label}</span>,
            }))}
          />
        </div>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={loginUser}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="username" rules={[{ required: true }]}>
                <Input className="custom-input" placeholder="Username" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input.Password
                  className="custom-input"
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
        <Link to="/register">
          <p>
            {t('frontend:authPage.no_account')}
            <b>{t('frontend:authPage.register')}</b>
          </p>
        </Link>
      </div>
      {isMobileOrTablet && url && (
        <div className="text-center">
          <a href={url} className="auth-button py-0 ant-btn">
            <Typography.Text className="py-2">
              {t('frontend:authPage.return')}
            </Typography.Text>
          </a>
        </div>
      )}
    </div>
  );
};

export default FeLogin;
