import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useCalendarTheme } from '../../theme';
import CalendarBodyLayer from './layer';

// touch drag drop
import { tchDrgDrp } from '../../dnd/touchDragDrop.js';
import { useSelector } from 'react-redux';
import { selectTimezone } from '../../../../features/calendar/calendarSlice';
import moment from 'moment';
import { useCallback } from 'react';
import { supportsTouch, getUnlock } from '../../util/lockdrag.js';

function DailyCalendarBody({
  hours = [],
  hourRef,
  date,
  columns,
  onCellClick,
}) {
  const timezone = useSelector(selectTimezone);
  const { computedStyles, theme, dateOption } = useCalendarTheme();

  // icon lock/unlock
  const unlockSetting = getUnlock();

  const touchOptions = {
    delayTouchStart: 20,
    delay: 20,
    enableTouchEvents: unlockSetting,
    enableMouseEvents: unlockSetting,
    getDropTargetElementsAtPoint: tchDrgDrp.getDropTargetElementsAtPoint,
  };

  const getLineTopOffset = useCallback(
    (hourHeight, hourOffset = 0) => {
      const date = moment().tz(timezone);
      return Math.ceil(
        hourHeight * (date.hours() - hourOffset) +
          (hourHeight / 60) * date.minutes(),
      );
    },
    [timezone],
  );

  const startingHour = hours[0];

  useEffect(() => {
    const date = moment().tz(timezone);
    let timeout;
    let interval;
    timeout = setTimeout(() => {
      interval = setInterval(() => {
        const timeLine = document.getElementById('current-time');
        if (timeLine) {
          timeLine.style.top =
            getLineTopOffset(theme.sidebar.hourHeight, startingHour) + 'px';
        }
      }, 60 * 1000);
    }, date.seconds() - 60);
    return () => {
      timeout && clearTimeout(timeout);
      interval && clearInterval(interval);
    };
  }, [theme, startingHour, timezone, getLineTopOffset]);

  return (
    <div
      className="calendar-body position-relative flex-row"
      style={computedStyles.calendarBodyHeightStyle}
      data-testid="calendar-body"
    >
      <div
        className="calendar-hours h-100"
        id="calendarscrollTop"
        ref={hourRef}
        style={computedStyles.calendarScrollerStyle}
        data-testid="calendar-hour-sidebar"
      >
        {hours.map((hour) => (
          <div
            className="hour sidebar-hour-text"
            key={`hour-${dateOption.date.format('YYYY-MM-DD')}-${hour}`}
            style={{ height: theme.sidebar.hourHeight }}
          >
            <p
              className="m-0 sidebar-hour-text"
              style={computedStyles.calendarSidebarHourHeight}
            >
              {hour}:00
            </p>

            <p className="m-0 sidebar-hour-text-light">{hour}:30</p>
          </div>
        ))}
      </div>

      <div
        className="calendar-container position-relative flex-1 w-100"
        data-testid="calendar-agenda-columns"
      >
        <DndProvider
          backend={supportsTouch ? TouchBackend : HTML5Backend}
          options={supportsTouch ? touchOptions : {}}
        >
          <div
            className="calendar-cell-background h-100 overflow-scroll flex-row position-relative"
            id="calendarscroller"
            style={{ marginRight: 60 }}
          >
            {date === new Date().toISOString().substring(0, 10) && (
              <div
                className="w-100 hour-line"
                id="current-time"
                style={{
                  top: getLineTopOffset(theme.sidebar.hourHeight, startingHour),
                }}
              ></div>
            )}
            {!!columns.length &&
              columns.map((column) => (
                <CalendarBodyLayer
                  key={`calendar-column-${dateOption.date.format(
                    'YYYY-MM-DD',
                  )}-${column.id}`}
                  hours={hours}
                  column={column}
                  onCellClick={onCellClick}
                />
              ))}
          </div>
        </DndProvider>
      </div>
    </div>
  );
}

DailyCalendarBody.propTypes = {
  template: PropTypes.object,
  columns: PropTypes.array.isRequired,
  hourRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  hours: PropTypes.array.isRequired,
  onCellClick: PropTypes.func,
};

export default DailyCalendarBody;
