export const supportsTouch =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const getUnlock = () => {
  if (!supportsTouch) return 1;
  const lk = parseInt(localStorage.getItem('unlockDrag') || '0');
  return lk;
};
