import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_MODE } from '../../app/constants';

/**
 * Client update or create form
 * @param {Object} param0
 * @returns
 */
function ProfileForm({ initialValue, onSubmit, mode }) {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const onFinishFailed = (errorInfo) => {
    //Handle failure
  };

  const layout = {
    labelCol: { offset: 1, span: 6 },
    wrapperCol: { span: 11 },
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [form, initialValue]);

  return (
    <Form
      {...layout}
      form={form}
      initialValues={initialValue}
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        labelAlign="left"
        name={'name'}
        label={t('profilepage.label.name')}
        rules={[{ required: true }]}
      >
        <Input size="large" placeholder={t('profilepage.placeholder.name')} />
      </Form.Item>

      <Form.Item
        labelAlign="left"
        name={'email'}
        label={t('profilepage.label.email')}
        rules={[{ required: true, type: 'email' }]}
      >
        <Input
          type={'email'}
          size="large"
          placeholder={t('profilepage.placeholder.email')}
        />
      </Form.Item>

      <Form.Item
        labelAlign="left"
        name={'password'}
        label={t('profilepage.label.password')}
        rules={[{ required: true }]}
      >
        <Input.Password
          className="inputBorder"
          size="large"
          required
          placeholder={t('profilepage.placeholder.password')}
        />
      </Form.Item>

      <Form.Item
        labelAlign="left"
        name={'password_confirmation'}
        label={t('profilepage.label.confirm_password')}
        rules={[{ required: true }]}
      >
        <Input.Password
          className="inputBorder"
          size="large"
          required
          placeholder={t('profilepage.placeholder.confirm_password')}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 7,
          span: 11,
        }}
      >
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button type="primary" htmlType="submit" className="secondary-button">
            {mode === CREATE_MODE
              ? t('profilepage.button.create')
              : t('profilepage.button.update')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

ProfileForm.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ProfileForm;
