import { PhoneOutlined } from '@ant-design/icons';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Alert, Card, Col, notification, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthHeader, TableServiceCard } from '../../components';
import {
  fetchShopDetail,
  shopDetail,
} from '../../features/frontend/frontendSlice';
import { frontendAuthRequest } from '../../services/api';
// import { getEventFromStorage } from '../../services/token';
import './ProfileReservationPage.less';
import TrashIcon from '../../components/icons/TrashIcon.svg';
import CancelModal from '../../components/modal/CancelModal';

const { Paragraph, Text } = Typography;

const Map = ({ children, style, ...options }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          ...options,
        }),
      );
    }
  }, [ref, map, options]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

/**
 * Routes component containing routes for the whole application
 *
 * @returns {JSX}
 */
export default function ProfileReservationPage() {
  const { t } = useTranslation();

  const shopData = useSelector(shopDetail);

  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countCancel, setCountCancel] = useState(0);
  const [cancModal, setCancModal] = useState(null);
  const userId = localStorage.getItem('fe-userId');

  const fetchApiData = useCallback(() => {
    setLoading(true);
    const eventId = localStorage.getItem('fe-eventId');
    frontendAuthRequest()
      .get(
        `api/business/v1/${eventId}/agenda_appointments?participant=${userId}`,
      )
      .then((res) => {
        if (res.status !== 200) return;
        setAppointments(groupAppointments(res.data.data.schedules));
      })
      .catch((err) => {
        notification['error']({
          message: 'Errore', // t('frontend:reservationpage.errorConnectMsg'),
          description:
            'Si è verificato un errore di connessione al server, prova più tardi.', //t('frontend:reservationpage.errorConnectDescr'),
        });
      })
      .finally((res) => {
        setLoading(false);
      });
  }, [userId]);

  // grouping appointments by day
  function groupAppointments(data) {
    let r,
      n,
      kn = [],
      ko = [],
      ida,
      item,
      price,
      dt = {},
      dta = [];

    for (r = 0; r < data.length; r++) {
      item = data[r];
      if (item.deleted) continue;

      // days: number; // oggi | Completato | Tra n giorni
      item.days = moment(item.start_date.substring(0, 10)).diff(
        moment().format('YYYY-MM-DD'),
        'days',
      );

      // date e.g. 2023-12-09
      const dateDay = moment(item.start_date).format('YYYY-MM-DD');

      if (!dt[dateDay]) {
        dt[dateDay] = {
          start_date: item.start_date,
          end_date: item.end_date,
          days: item.days,
          total: 0,
          services: [],
          appointmentIds: [],
        };

        if (item.days < 0) ko.push(dateDay);
        else kn.push(dateDay);
      } else {
        // first start_date
        if (item.start_date < dt[dateDay].start_date) {
          dt[dateDay].start_date = item.start_date;
        }
        // last end_date
        if (item.end_date > dt[dateDay].end_date) {
          dt[dateDay].end_date = item.end_date;
        }
      }

      ida = item.id;
      if (dt[dateDay].appointmentIds.indexOf(ida) === -1)
        dt[dateDay].appointmentIds.push(ida);

      // services && total
      for (n = 0; n < item.services.length; n++) {
        // total
        price = item.services[n].price < 0 ? 0 : item.services[n].price;
        dt[dateDay].total += price;
        // service
        dt[dateDay].services.push({
          name: item.services[n].title,
          operator: item.staff.name,
          time:
            item.services[n].duration || item.services[n].execution_time || 0,
          amount: price,
          appointmentId: item.id,
          rowspan: 0,
        });
      }
    } //

    // mix sort keys
    kn.sort();
    ko.sort().reverse();
    const keys = kn.concat(ko);
    for (r = 0; r < keys.length; r++) dta.push(dt[keys[r]]);

    return dta;
  }

  useEffect(() => {
    fetchApiData();
  }, [fetchApiData]);

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  useEffect(() => {
    if (!Object.keys(shopData).length) {
      dispatch(fetchShopDetail());
    }
  }, [dispatch, shopData]);

  // request cancel single appointment
  const cancelAppointment = (appointmentId) => {
    setLoading(true);

    const eventId = localStorage.getItem('fe-eventId');
    frontendAuthRequest()
      .post(
        `/api/business/v1/${eventId}/agenda_appointments/${appointmentId}/cancel_appointment?locale=${localStorage.getItem(
          'lang',
        )}`,
        {
          delete: true,
          delete_note: 'customer_canceled',
        },
      )
      .then((res) => {
        notification['success']({
          message: t('frontend:reservationpage.ok_deletion'),
        });

        const count = countCancel - 1;
        setCountCancel(count);
        if (count <= 0) fetchApiData();
      })
      .catch((err) => {
        notification['error']({
          message: t('frontend:reservationpage.no_deletion'),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // cancel single
  const cancelOneAppointment = (id) => {
    setCountCancel(1);
    cancelAppointment(id);
  };

  const getAppointmentText = (days) => {
    if (days === 0) return t('frontend:reservationpage.today');
    return days < 0
      ? t('frontend:reservationpage.passedReservation')
      : (days === 1
          ? t('frontend:reservationpage.in_day1')
          : t('frontend:reservationpage.in_day12')) +
          days +
          (t('frontend:reservationpage.in_day2') +
            (days === 1
              ? t('frontend:reservationpage.in_day3')
              : t('frontend:reservationpage.in_day4')));
  };

  const toFirstUpperCase = (txt) => {
    txt = txt || '-';
    let ch = txt.charAt(0).toUpperCase();
    return ch + txt.substring(1);
  };

  const handleCancOk = () => {
    if (!cancModal) return false;
    setCountCancel(cancModal.appointmentIds.length);
    cancModal.appointmentIds.map((id) => cancelAppointment(id));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setCancModal(null);
  };

  // console.log('shopData', shopData);
  //console.log('new-data:', appointments);

  return (
    <div className="main-page reservation-page">
      <AuthHeader title={shopData ? shopData.name : ''} />

      <div className="wrapperScroll">
        {!!cancModal && (
          <CancelModal
            open={cancModal ? true : false}
            title={t('frontend:reservationpage.warning')}
            content={t('frontend:reservationpage.confirm_delete_all')}
            handleOK={handleCancOk}
            closeModal={handleCloseModal}
          />
        )}
        <Row
          className="bg-light-grey"
          style={{ minHeight: 'calc(100vh - 80px)' }}
        >
          <Col
            xs={24}
            md={{ span: 18, offset: 3 }}
            style={{ marginTop: '1.5rem' }}
          >
            <div className="px-3">
              <Card className="reservation-card">
                <div className="map" style={{ minHeight: 150 }}>
                  <Wrapper
                    apiKey={'AIzaSyANZw-8LWf9wHhdDwrgdI2Lp_4PZWNzPx4'}
                    render={render}
                  >
                    <Map
                      center={{
                        lat: shopData.latitude,
                        lng: shopData.longitude,
                      }}
                      zoom={14}
                      style={{ flexGrow: '1', height: 150 }}
                    ></Map>
                  </Wrapper>
                </div>

                <div
                  className="d-flex flex-row justify-content-between gap-4"
                  style={{ margin: '20px 0 -20px 0' }}
                >
                  <Paragraph>Via {shopData.location}</Paragraph>

                  <div>
                    {shopData.phone && String(shopData.phone).length && (
                      <>
                        <a href={`tel:${shopData.phone}`}>
                          <PhoneOutlined />
                        </a>
                        <Text className="px-1">
                          {t('frontend:reservationpage.call')}
                        </Text>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </div>

            <div className="profile-title">
              {t('frontend:reservationpage.my_appointments')}
            </div>

            <Spin spinning={loading} size="large" style={{ marginTop: '50px' }}>
              {appointments.map((appointment) => (
                <div
                  className="px-3"
                  key={`${appointment.start_date}-appointment`}
                >
                  <Card className="reservation-card">
                    <Text
                      className="card-badge text-dark"
                      style={{
                        backgroundColor: appointment.days < 0 ? '#ddd' : '#cec',
                      }}
                    >
                      {getAppointmentText(appointment.days)}
                    </Text>

                    <div
                      className="d-flex flex-row justify-content-between"
                      style={{ marginTop: '14px' }}
                    >
                      <Paragraph className="text-dark text-right">
                        {toFirstUpperCase(
                          moment(appointment.start_date).format(
                            'dddd D MMMM YYYY',
                          ),
                        )}
                      </Paragraph>

                      <Paragraph style={{ marginTop: '-20px' }}>
                        {`${t('calendar.hours')}:`} <br />
                        {moment(appointment.start_date).format('LT') +
                          ' - ' +
                          moment(appointment.end_date).format('LT')}
                      </Paragraph>
                    </div>

                    <TableServiceCard
                      data={appointment}
                      onCancelAppointment={cancelOneAppointment}
                    />

                    <div style={{ marginBottom: '-10px' }}>
                      <div className="d-flex flex-row justify-content-between py-1">
                        {appointment.days >= 0 && (
                          <div
                            className="cancelDecoration"
                            onClick={() => {
                              setCancModal(appointment);
                            }}
                          >
                            <img
                              src={TrashIcon}
                              style={{
                                marginTop: '-4px',
                                height: '16px',
                                paddingRight: '7px',
                              }}
                              alt={t('frontend:reservationpage.delete_all')}
                            />
                            {t('frontend:reservationpage.cancel_day')}
                          </div>
                        )}

                        <div> </div>

                        <div>
                          <Text className="total font-weight-bold">
                            {t('frontend:reservationpage.total')} &nbsp;&nbsp;€{' '}
                            {appointment.total || 0}
                          </Text>
                        </div>
                      </div>
                    </div>

                    {appointment.days >= 0 && (
                      <Alert
                        style={{ marginTop: '20px' }}
                        message={
                          t('frontend:reservationpage.cancellationText1') +
                          (shopData?.delete_notice_hours || 24) +
                          t('frontend:reservationpage.cancellationText2')
                        }
                        type="warning"
                      />
                    )}
                  </Card>
                </div>
              ))}
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
}
